
//Mediaqueries - Desktop
@mixin ms-desktop {
    @media (min-width: 1440px) and (max-width: 1919px){ 
        @content; 
    }
}

//Mediaqueries - Laptop
@mixin ms-laptop {
    @media (min-width: 1280px) and (max-width: 1439px){ 
        @content; 
    }
}

//Mediaqueries - Tablet lg
@mixin ms-tablet-lg {
    @media (min-width: 993px) and (max-width: 1279px){ 
        @content; 
    }
}

//Mediaqueries - Tablet
@mixin ms-tablet {
    @media (min-width: 767px) and (max-width: 992px){ 
        @content; 
    }
}

//Mediaqueries - Mobile
@mixin ms-mobile {
    @media (max-width: 576px){ 
        @content; 
    }
}