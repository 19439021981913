//Home animation styles

.home-first{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: map.get($home-first, "bk");
    .data-container{
        position:relative;
        padding:50px;
        .hola{
            font-size: 180px;
            font-weight: 600;
            -webkit-text-stroke: map.get($home-first, "hola");
            color:transparent;
            position: relative;
            display:flex;
        }
        .soy{
            margin-top: -70px;
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-first, "soy");
            --animate-delay: 0.3s;
            --animate-duration: 0.8s;
            display:flex;
        }
        .maru{
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-first, "maru");
        }
    }
    .form-1,
    .form-2,
    .form-3,
    .form-4{
        display:none;
    }
}

.home-second{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: map.get($home-second, "bk");
    .data-container{
        position:relative;
        padding:50px;
        .hola{
            font-size: 180px;
            font-weight: 600;
            -webkit-text-stroke: map.get($home-second, "hola");
            color:transparent;
            position: relative;
            display: flex;
        }
        .soy{
            margin-top: -70px;
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-second, "soy");
            animation-delay: 0s;
            display: flex;
        }
        .maru{
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-second, "maru");
            text-shadow: map.get($home-second, "maru-shadow");
        }
    }
    .form-1{
        position:absolute;
        right:0;
        top:0;
        height:100vh;
        width:100px;
        z-index:100;
        background-color: map.get($home-second, "form-1");
        @include expandingStripe(100px);
        animation-name: expandingStripe;
        animation-duration: 0.8s;
    }
    .form-2{
        position:absolute;
        right:0;
        top:0;
        height:100vh;
        width:200px;
        z-index:90;
        background-color: map.get($home-second, "form-2");
        box-shadow: inset -113px 0px 8px -4px rgba(0,0,0,0.1);
        @include expandingStripe(200px);
        animation-name: expandingStripe;
        animation-duration: 0.8s;
    }
    .form-3{
        position:absolute;
        right:0;
        top:0;
        height:100vh;
        z-index:80;
        width:300px;
        background-color: map.get($home-second, "form-3");
        box-shadow: inset -216px 0px 8px -4px rgba(0,0,0,0.1);
        @include expandingStripe(300px);
        animation-name: expandingStripe;
        animation-duration: 0.8s;
    }
    .form-4{
        position:absolute;
        right:0;
        top:0;
        height:100vh;
        width:400px;
        z-index:70;
        background-color: map.get($home-second, "form-4");
        box-shadow: inset -319px 0px 8px -4px rgba(0,0,0,0.1);
        @include expandingStripe(400px);
        animation-name: expandingStripe;
        animation-duration: 0.8s;
    }
}

.home-third{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: map.get($home-third, "bk");
    .data-container{
        position:relative;
        padding:50px;
        .hola{
            font-size: 180px;
            font-weight: 600;
            text-shadow: map.get($home-third, "hola-shadow");
            color: map.get($home-third, "hola");
            position: relative;
            display: flex;
        }
        .soy{
            margin-top: -70px;
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-third, "soy");
            display: flex;
        }
        .maru{
            font-size: 180px;
            font-weight: 600;
            color:map.get($home-third, "maru");
        }
    }
    .form-1{
        position:absolute;
        height:40px;
        width:40px;
        z-index:100;
        border-radius: 50%;
        background-color: map.get($home-third, "form-1");
        animation-name: floatBlue;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration:10s;
        @include floatingDot(5px, 10px, "Blue");
    }
    .form-2{
        position:absolute;
        height:60px;
        width:60px;
        z-index:100;
        border-radius: 50%;
        background-color: map.get($home-third, "form-2");
        animation-name: floatYellow;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration:10s;
        @include floatingDot(3px, 15px, "Yellow");
    }
    .form-3{
        position:absolute;
        height:80px;
        width:80px;
        z-index:100;
        border-radius: 50%;
        background-color: map.get($home-third, "form-3");
        animation-name: floatPink;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration:10s;
        @include floatingDot(30px, 4px, "Pink");
    }
    .form-4{
        position:absolute;
        height:120px;
        width:120px;
        z-index:100;
        border-radius: 50%;
        background-color: map.get($home-third, "form-4");
        animation-name: floatWhite;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration:10s;
        @include floatingDot(5px, 10px, "White");
    }
}

.home-fourth{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .data-container{
        position:relative;
        padding:50px;
        .hola{
            font-size: 180px;
            font-weight: 600;
            color: map.get($home-fourth, "hola");
            position: relative;
            display:flex;
        }
        .soy{
            margin-top: -70px;
            font-size: 180px;
            font-weight: 600;
            color: map.get($home-fourth, "soy");
            animation-delay:0s;
            display:flex;
        }
        .maru{
            font-size: 180px;
            font-weight: 600;
            color: map.get($home-fourth, "maru");
        }
    }
    .form-1{
        position:absolute;
        z-index:-1;
        top:0;
        left:0;
        background-color:  map.get($home-fourth, "form-1");
        height:100%;
        width:110%;
        object-fit: cover;
        animation-name: wavyWaves;
        animation-duration:4s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        content:url('../../images/waves.svg');
    }
    
}

.home-fifth{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    .data-container{
        position:relative;
        padding:50px;
        z-index:10;
        .hola{
            font-size: 180px;
            font-weight: 600;
            text-shadow:-7px 7px $yellow;
            -webkit-text-stroke:3px #202020;
            color:transparent;
            position: relative;
            cursor:default;
            display:flex;
            .exclamation-mark{
                display:inline-block;
                transform:rotate(0deg);
                transition: all 0.4s ease;
            }
            // animation-name:resizeTypo;
            // animation-duration: 1s;
            // animation-timing-function: ease;
            // animation-direction: normal;
            // animation-fill-mode: forwards;
            &:hover{
                .exclamation-mark{
                    display:inline-block;
                    transform:rotate(8deg);
                    transition: all 0.4s ease;
                }
            }
        }
        .soy{
            margin-top: -70px;
            font-size: 180px;
            font-weight: 600;
            color:$green;
            display:flex;
            // animation-name:resizeTypo;
            // animation-duration: 1s;
            // animation-timing-function: ease;
            // animation-direction: normal;
            // animation-fill-mode: forwards;
            // animation-delay: 0s;
        }
        .maru{
            font-size: 180px;
            font-weight: 600;
            color:$pink;
            transition: all 0.4s ease;
            cursor:default;
            content:attr(data-descr);
            // animation-name:resizeTypo;
            // animation-duration: 1s;
            // animation-timing-function: ease;
            // animation-direction: normal;
            // animation-fill-mode: forwards;
            &:hover{
                -webkit-text-stroke:2px #fff;
                text-shadow: -5px 5px $yellow, -10px 10px $green;
                transition: all 0.4s ease;
                content:'Maru ;)';
            }
        }
    }
    .form-1{
        position:absolute;
        height:800px;
        width:800px;
        z-index:0;
        opacity:0.1;
        background-color: $yellow;
        border-radius: 58% 42% 70% 30% / 30% 30% 70% 70% ;
        animation-name:moveYellow;
        animation-duration: 80s;
        animation-timing-function: ease-in;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        @include moveGiantDots(-250px, 20px, 200px, 100px, "Yellow");
    }
    .form-2{
        position:absolute;
        height:700px;
        width:700px;
        z-index:0;
        opacity:0.15;
        background-color: $pink;
        border-radius: 50% 50% 37% 63% / 20% 59% 41% 80% ;
        animation-name:movePink;
        animation-duration: 100s;
        animation-timing-function: ease-in;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        @include moveGiantDots(-100px, 1500px, 200px, 100px, "Pink");
    }
    .form-3{
        position:absolute;
        height:500px;
        width:500px;
        z-index:0;
        opacity:0.15;
        background-color: $green;
        border-radius: 50% 50% 37% 63% / 73% 40% 60% 27% ;
        animation-name:moveGreen;
        animation-duration: 80s;
        animation-timing-function: ease-in;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        @include moveGiantDots(600px, 800px, 400px, 600px,"Green");
    }
    // .form-4{
    //     position:absolute;
    //     height:120px;
    //     width:120px;
    //     z-index:100;
    //     border-radius: 50%;
    //     background-color: #CFCDCE;
    // }
}


//Mediaqueries
@include ms-mobile{
    .home-first,.home-second,.home-third,.home-fourth,.home-fifth{
        .data-container{
            padding: 50px 20px;
        }
        .hola,.soy,.maru{
            font-size: 80px !important;
        }
        .soy{
            margin-top: -19px !important;
            line-height: 90px;
        }
    }
    .home-fifth .data-container .hola{
        text-shadow: -4px 4px #ffb100;
        -webkit-text-stroke: 1px #202020;
    }
    .home-fifth{
        .form-1{
            width:100px;
            height:100px;
        }
        .form-2{
            width:200px;
            height:200px;
            @include moveGiantDots(-100px, 100px, 200px, 100px, "Pink");
        }
        .form-3{
            width:50px;
            height:50px;
            @include moveGiantDots(200px, 200px, 400px, 300px,"Green");
        }
    }
    .home-first{
        .hola{
            -webkit-text-stroke: 1px black !important;
        }
    }
    .home-second{
        .data-container{
            position:relative;
            z-index:200;
        }
        .hola{
            -webkit-text-stroke: 1px $white !important;
        }
        .form-1{
            width:20px;
        }
        .form-2{
            width:40px;
        }
        .form-3{
            width:60px;
        }
        .form-4{
            width:80px;
        }
    }
}
@include ms-tablet{
    .home-first,.home-second,.home-third,.home-fourth,.home-fifth{
        .data-container{
            padding: 50px 20px;
        }
        .hola,.soy,.maru{
            font-size: 100px !important;
        }
        .soy{
            margin-top: -36px !important;
            line-height: 162px;
        }
    }
    .home-fifth .data-container .hola{
        text-shadow: -4px 4px #ffb100;
        -webkit-text-stroke: 2px #202020;
    }
    .home-fifth{
        .form-1{
            width:300px;
            height:300px;
        }
        .form-2{
            width:500px;
            height:500px;
            @include moveGiantDots(-100px, 200px, 200px, 100px, "Pink");
        }
        .form-3{
            width:200px;
            height:200px;
            @include moveGiantDots(200px, 200px, 400px, 400px,"Green");
        }
    }
    .home-second{
        .form-1{
            width:40px;
        }
        .form-2{
            width:80px;
        }
        .form-3{
            width:120px;
        }
        .form-4{
            width:160px;
        }
    }
}
@include ms-tablet-lg{
    .home-first,.home-second,.home-third,.home-fourth,.home-fifth{
        .data-container{
            padding: 50px 20px;
        }
        .hola,.soy,.maru{
            font-size: 150px !important;
        }
        .soy{
            margin-top: -36px !important;
            line-height: 162px;
        }
    }
    .home-fifth .data-container .hola{
        text-shadow: -7px 7px #ffb100;
        -webkit-text-stroke: 3px #202020;
    }
    .home-fifth{
        .form-1{
            width:500px;
            height:500px;
        }
        .form-2{
            width:700px;
            height:700px;
            @include moveGiantDots(-100px, 200px, 200px, 100px, "Pink");
        }
        .form-3{
            width:300px;
            height:300px;
            @include moveGiantDots(200px, 200px, 400px, 400px,"Green");
        }
    }
    .home-second{
        .form-1{
            width:40px;
        }
        .form-2{
            width:80px;
        }
        .form-3{
            width:120px;
        }
        .form-4{
            width:160px;
        }
    }
}