@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*{
    font-family: 'Poppins', sans-serif;
}

//Colors
.text-green{
    color:$green;
}

//Weight
.text-medium{
    font-weight: 500;
}