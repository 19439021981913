
.social-media-button{
    width:50px;
    height:50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #202020;
    background-color: $white !important;
    margin-right:20px;
    transition: all 0.3s ease;
    .social-media-icon{
        width: 30px;
        height: 30px;
    }
    &:hover{
        transform: translateY(-4px);
        transition: all 0.3s ease;
    }
    &.green{
        box-shadow: -3px 3px $green;
    }
    &.yellow{
        box-shadow: -3px 3px $yellow;
    }
    &.pink{
        box-shadow: -3px 3px $pink;
    }
    @include ms-mobile{
        width:40px;
        height:40px;
        background-color: $white !important;
        .social-media-icon{
            width: 25px;
            height: 25px;
        }
    }
}