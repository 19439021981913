
//Home Animations

@mixin expandingStripe($stripeWidth){
    @keyframes expandingStripe{
        from{
            height:0;
        }
        to{
            height: 100%;
        }
    }
}
@mixin floatingDot($firstPositionY, $firstPositionX, $dotName){
    @keyframes float#{$dotName}{
        @for $i from 0 through 50{

            $currentPositionX: $firstPositionX * $i;
            $currentPositionY: $firstPositionY * $i;

            @if($dotName == "Yellow"){
                #{$i + "%"}{
                    top:$currentPositionY;
                    left:$currentPositionX;
                }
            }
            @if($dotName == "Blue"){
                #{$i + "%"}{
                    bottom:$currentPositionY;
                    left:$currentPositionX;
                }
            }
            @if($dotName == "Pink"){
                #{$i + "%"}{
                    bottom:$currentPositionY;
                    right:$currentPositionX;
                }
            }
            @if($dotName == "White"){
                #{$i + "%"}{
                    top:$currentPositionY;
                    right:$currentPositionX;
                }
            }
        }
    }
}
@keyframes resizeTypo{
    from{
        font-size: 180px;
    }
    to{
        font-size: 100px;
    }
}
@keyframes wavyWaves{
    0%{
        object-position: 0px 0px;
    }
    100%{
        object-position: 0px -400px;
    }
}
@mixin moveGiantDots($firstPositionY, $firstPositionX, $lastPositionY, $lastPositionX, $dotName){
    @keyframes move#{$dotName}{
        from{
            transform:translate($firstPositionX, $firstPositionY);
        }
        to{
            transform:translate($lastPositionX, $lastPositionY);
        }
    }
}
@keyframes shake{
    0%{
        transform:translate(4px, 0px);
    }
    50%{
        transform:translate(-4px, 0px);
    }
    100%{
        transform:translate(4px, 0px);
    }
}

